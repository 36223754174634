.bold-text {
  font-weight: bold;
}

.my-line {
  background-color: #000;
  height: 2.5rem;
}

.footer-bg{
  background-color: #e7e7e7;
}

.footer-contact-btn {
  text-decoration: none;
  color: rgb(255, 255, 255) !important;
  background-color: #000 !important;
  border-radius: 10px;
  width: auto;
  padding: .5rem !important;
}


.footer-logo {
  width: 125px;
  height: auto;
  margin-bottom: 20px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
  .footer-logo {
    width: 150px;
    /* Adjust as needed */
  }

  .footer-contact-btn {
    font-size: 0.8rem;
    /* Adjust as needed */
    padding: 0.3rem !important;
    /* Adjust as needed */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* You might not need additional adjustments for medium devices */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .footer-logo {
    width: 200px;
    /* Adjust as needed */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* You might not need additional adjustments for extra large devices */
}

/* Base styles */
.my-footer {
  /* Your footer styles */
}

.container-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-bottom {
  /* Styles for the left-bottom text */
}

.custom-icons {
  display: flex;
  width: 200px;
  /* Fixed width for the custom icon container */
}

.icon-bg {
  margin-right: 10px;
  /* Adjust spacing between icons */
}

.social-media-icon {
  width: 35%;
  /* Set the width of social media icons to fill the container */
  height: auto;
  /* Ensure aspect ratio is maintained */
}

/* Responsive styles */
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
  .container-footer {
    flex-direction: column;
    /* Stack items vertically on small screens */
  }

  .left-bottom {
    text-align: center;
    /* Center-align text on small screens */
  }

  .custom-icons {
    width: auto;
    /* Allow the custom icon container to adjust its width */
    justify-content: center;
    /* Center-align icons on small screens */
  }

  .social-media-icon {
    width: 50%;
    /* Set the width of social media icons to fill the container */
    height: auto;
    /* Ensure aspect ratio is maintained */
  }

  .icon-bg {
    margin-right: 5px;
    /* Adjust spacing between icons on small screens */
  }
}

.site-map {
  text-align: left;
}

.site-map-title {
  font-weight: bold;
  text-align: left;
}

.site-map-products{
  font-size: 20px;
  font-weight: bold;
}

.site-map-link {
  display: block;
  text-decoration: none;
  color: #000;
  margin-bottom: 1rem;
  transition: color 0.3s,; /* Add transition for color and text-decoration */
}

.site-map-link:hover {
  color: #0000009a; /* Change the color on hover */
}