.home-page {
    padding-top: 182px;
}

.custom-navbar-brand {
    /* background-color: red !important; */
}

.carousel-indicators {
    display: none !important;
}

.custom-navbar {
    height: 50px;
    display: flex;
}

.title-white {
    color: #000;
    font-weight: bold;
}

.my-header {
    display: flex;
    justify-content: end;
    background-color: black;
    color: #000;
    margin-top: 2rem;
}

.my-header2 {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.66);
    color: #000;
    margin-top: 2rem;
}

.mr-items {
    margin-right: 20px;
}



.nav-link {
    color: #fff !important;
}

.navbar-collapse {
    flex-grow: 0 !important;
}



.searchIcon {
    height: 2rem;
    width: 100%;
}


.my-line {
    background-color: #000;
    height: 2.5rem;
}

.second-line {
    background-color: #000;
    height: 2.5rem;
    margin-top: 2rem;
}

.exec-summary {
    padding-top: 1rem;
}

/* .exec-summary-images {
    height: 30rem;
    width: 75%;
    margin-top: 1rem;
  } */

.exec-summary-images2 {
    height: 10rem;
    width: 75%;
    margin-top: 1rem;
}

.exec-summary-images:hover {
    transform: scale(1.3);
    /* margin-top: .5rem;
    margin-bottom: .5rem; */
}

my-column {
    position: relative;
}

.image-container {
    position: relative;
    overflow: hidden;
}

.exec-summary-images {
    height: 30rem;
    width: 75%;
    margin-top: 1rem;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    /* Match the width of the image */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    /* Adjust as needed */
}

.my-text:first-child {
    margin-bottom: 10px;
    /* Margin between paragraphs */
}

.card-hover {
    width: 100%;
    height: 600px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    /* Change border-radius to 50% for circular shape */
}



.card-hover__content {
    width: 100%;
    text-align: center;
    background-color: #11111120;
    backdrop-filter: blur(10px);
    /* Transparent background */
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(0);
    transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
    will-change: top, background-color, transform, padding;
    z-index: 1;
    padding: 20px 0;
    /* Adjust padding */
}


/* .card-hover__content::before,
.card-hover__content::after {
    content: "";
    width: 100%;
    height: 100px;
    background-color: inherit;
    position: absolute;
    left: 0;
    z-index: -1;
} */

/* .card-hover__content::before {
    top: -60px;
    clip-path: ellipse(70% 90px at bottom center);
}

.card-hover__content::after {
    bottom: -60px;
    clip-path: ellipse(70% 90px at top center);
} */

.card-hover:hover .card-hover__content {
    /* background-color: #000000; */
    top: 0;
    transform: translateY(80%);
    padding: 50px 60px;
    /* Adjust padding */
    transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

.card-hover:hover .card-hover__link {
    opacity: 1;
    transform: translate(-50%, -10%);
    transition: all 0.3s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

/* .card-hover:hover img {
    transform: scale(1.1);
} */

.card-hover__title {
    font-size: 1.5rem;
    font-weight: bolder;
    margin-bottom: 0.5em;
    color: #fff;
    text-decoration: none;
}

/* .card-hover__title span {
    color: #2d7f0b;
} */

.card-hover__text {
    margin-top: 1rem;
    font-size: 0.95rem;
    margin-bottom: 0.5em;
    padding-left: 7rem;
    padding-right: 7rem;
    color: #fff
}

.card-hover__link {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, -10%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    opacity: 0;
    padding: 10px;
    transition: all 0.35s;
    cursor: pointer;
}

.card-hover__link:hover {
    color: #6ee073;
}

.card-hover__link:hover svg {
    transform: translateX(4px);
}

.card-hover__link svg {
    width: 18px;
    margin-left: 4px;
    transition: transform 0.3s;
}

.card-hover img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    transform: scale(1.2);
    transition: 0.35s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
}



/* Responsive styles */
@media (max-width: 767px) {
    .my-column {
        margin-bottom: 20px;
    }

    .card-hover {
        width: 100%;
        height: 330px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);
        border-radius: 50%;
        /* Change border-radius to 50% for circular shape */
    }
    .card-hover:hover {
        width: 100%;
        height: 330px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);
        border-radius: 50%;
        /* Change border-radius to 50% for circular shape */
    }


    .card-hover__content {
        padding: 20px 10px;
        top: 138px;
        /* Adjusted padding for better spacing */
    }

    /* .card-hover__content::before {
        top: -37px;
        clip-path: ellipse(70% 90px at bottom center);
    }

    .card-hover__content::after {
        bottom: -40px;
        clip-path: ellipse(70% 90px at top center);
    } */

    .card-hover__title {
        font-size: 1.3rem;
        /* Increase font size for better readability */
    }

    .card-hover__text {
        padding-left: 1.5rem;
        /* Adjusted padding for better alignment */
        padding-right: 1.5rem;
        /* Adjusted padding for better alignment */
        font-size: 0.9rem;
        /* Decrease font size for better readability */
    }

    .card-hover:hover .card-hover__content {
        /* background-color: #000000; */
        top: 0;
        transform: translateY(47%);
        padding: 20px 10px;
        /* Adjust padding */
        transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
    }
}



@media (min-width: 768px) and (max-width: 991px) {
    .card-hover__content {
        padding: 30px 20px;
    }

    .card-hover__title {
        font-size: 1rem;
    }

    .card-hover__text {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .card-hover:hover .card-hover__content {
        /* background-color: #000000; */
        top: 0;
        transform: translateY(40%);
        padding: 50px 40px;
        /* Adjust padding */
        transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .card-hover__content {
        padding: 40px 30px;
    }

    .card-hover__title {
        font-size: 1.4rem;
    }

    .card-hover__text {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.container-carousel2 {
    z-index: 100;
}

.d-block1 {
    height: 52vh;
    width: 100%;
    background: url("../images/home/10_2311223577\ Hydrogen\ Fueling\ the\ Future_s\ Energy\ Transition.jpg") center center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
}

.d-block1 h1 {
    width: 35%;
    margin-right: 20px;
    padding-bottom: 20px;
}

.d-block2 {
    height: 52vh;
    width: 100%;
    background: url("../images/home/11_660549931\ Empowering\ the\ Future\ Harnessing\ Gas\ Power\ for\ Sustainable\ Progress.jpg") center center/cover no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.d-block2 h1 {
    width: 50%;
    padding: 10px;
    color: #fff;
    margin-bottom: 10px;
}

.d-block3 {
    height: 52vh;
    width: 100%;
    background: url("../images/home/12_2201000683\ Energizing\ Tomorrow\ Championing\ Renewable\ Power\ Today.jpg") center center/cover no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.d-block3 h1 {
    width: 50%;
    margin-left: 20px;
    padding: 10px;
    color: #fff;

}

.d-block4 {
    height: 52vh;
    width: 100%;
    background: url("../images/home/13_1099403360\ Trust\ Us\ with\ Your\ Assets\ Your\ Partner\ for\ Progress\ and\ Growth.jpg") center center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.d-block4 h1 {
    width: 35%;
    margin-right: 60px;
    padding: 10px;
    color: #fff;
}

.d-block5 {
    height: 52vh;
    width: 100%;
    background: url("../images//home/14_395645857_Unlocking\ Energy\ Potential\ Partner\ with\ Us\ for\ a\ Transformed\ Future.jpg") center center/cover no-repeat;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
}

.d-block5 h1 {
    margin-top: 40px;
}

.react-multi-carousel-item {
    margin-right: 4rem;
    margin-left: 4rem;
}

.proud {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.proud-image {
    width: 75%;
    height: 60vh;
    /* Transparent black background color */
}

/* Additional styles for smaller screens */
@media (max-width: 767px) {
    .proud-image {
        width: 90%;
        /* Adjust width for smaller screens */
        height: 40vh;
        /* Adjust height for smaller screens */
    }
}


.proud-text {
    position: absolute;
    color: rgb(255, 255, 255);
    padding: 0.5rem;
    text-align: center;
    font-size: 3rem;
    width: 50%;
    bottom: 30px;
}

/* Additional styles for smaller screens */
@media (max-width: 767px) {
    .proud-text {
        font-size: 2rem;
        /* Decrease font size for smaller screens */
        width: 80%;
        /* Adjust width for smaller screens */
        padding: 2.3rem;
        /* Adjust padding for smaller screens */
    }
}

.content {
    width: 70%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.definition-title {
    text-align: center;
    /* Align text to the center */
    font-weight: bold;
    font-size: 3.5vw;
    /* Responsive font size */
    width: 80%;
    margin: 2rem auto;
}

.twoZeroFiveZero {
    text-align: center;
    font-size: 15vw;
    font-weight: 400;
    background-color: #000;
    color: #fff;
    border-radius: 70%;
}

/* Additional styles for smaller screens */
@media (max-width: 767px) {
    .definition-title {
        font-size: 6vw;
        /* Adjust font size for smaller screens */
        width: 90%;
        /* Adjusted width for smaller screens */
    }
}

.definition-text {
    text-align: center;
    /* Align text to the center */
    font-weight: 700;
    font-size: 1.5vw;
    /* Responsive font size */
    width: 60%;
    margin: 2rem auto;
}

/* Additional styles for smaller screens */
@media (max-width: 767px) {
    .definition-text {
        font-size: 3.5vw;
        /* Adjust font size for smaller screens */
        width: 80%;
        /* Adjusted width for smaller screens */
    }
}

.sub-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: start;
}

.sub-items-card {
    height: 300px;
    /* Set a fixed height for the card */
    width: 100%;
    /* Ensure the card fills its container */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    text-decoration: none !important;
    margin-bottom: 15px;
}

/* Additional styles for smaller screens */
@media (max-width: 767px) {
    .sub-items-card {
        width: 100%;
        /* Ensure card takes full width on smaller screens */
    }
}

/* .sub-items-card:hover {
    transform: translateY(-5px);
} */

.card-body p {
    font-weight: bold;
    font-size: 12px;
}

.exec-summary-images2 {
    align-self: center;
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    object-position: center;
}

.sub-items-card img.exec-summary-images2 {
    width: 100%;
    /* Ensure the image fills the container */
    height: auto;
    /* Maintain aspect ratio */
    max-height: 150px;
    /* Limit maximum height */
    object-fit: cover;
    /* Cover the container with the image */
    object-position: center;
    /* Center the image */
}

.sub-items-card .card-body {
    flex: 1;
    /* Make the card body grow to fill the height */
}

.ads-icon {
    width: 50%;
    height: auto;
}

.comment-icon {
    width: 10%;
    height: auto;
}

.iso-images {
    width: 40%;
    height: auto;
}

.my-footer {
    background-color: black;
    color: #fff;
    height: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.container-footer {
    width: 80%;
    display: flex;
    justify-content: center;
}

.footer-logo {
    height: 6rem;
    width: 100%;
}

.icon-bg {
    background-color: #fff;
    margin-right: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.media-icon {
    width: 35%;
    height: auto;
}

.left-bottom {
    width: 70%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.left-bottom p {
    margin: 0;
    /* Remove default margin */
}

.custom-icons {
    display: flex;
    width: 30%;
    justify-content: end;
    align-items: center;
}

.bold-text {
    font-weight: bold;
}