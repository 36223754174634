.career-img {
    width: 100%;
    height: auto;
    max-height: 300px; /* Adjust the maximum height as needed */
    object-fit: cover; /* Ensures the image covers the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.career-container {
    padding-bottom: 50px; /* Add some space at the bottom */
    padding-top: 182px;
}

.career-container h2 {
    color: #333; /* Change the heading color */
    margin-bottom: 20px; /* Add space below headings */
}

.career-container p {
    margin-bottom: 10px; /* Add space below paragraphs */
}

.career-area,
.career-opportunity {
    background-color: #f9f9f9; /* Change the background color of the sections */
    padding: 20px; /* Add some padding to the sections */
    border-radius: 10px; /* Add rounded corners to the sections */
    text-align: left;
}

.career-area h2,
.career-opportunity h2 {
    margin-bottom: 20px; /* Add space below headings */
}

.career-opportunity button {
    margin-top: 10px !important;
}

div .form-label {
    text-align: left !important;
}

.main-title {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.second-title{
    font-weight: bold;
    font-style: italic;
}