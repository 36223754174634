.logo {
    width: 250px;
    height: auto;
}

.header-line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000;
    height: 2.5rem;
    z-index: 999;
    position: fixed;
    /* Added this line */
    top: 0;
    /* Align to the top of the page */
}

/* Additional styles for smaller screens */
@media (max-width: 767px) {
    .header-line {
        height: 2.4rem;
        /* Decrease height for smaller screens */
    }
}

.navbar-background {
    background-color: #11111120;
    backdrop-filter: blur(10px);
    /* Your desired color */
    width: 100%;
    /* Ensure it spans the full width */
    z-index: 998;
    /* Lower z-index than .header-line */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Adds a subtle shadow */



}

.carousel-control-prev .carousel-control-prev-icon {
    display: none;
}

.carousel-control-next .carousel-control-next-icon {
    display: none;
}

.fixed-top {
    /* Base style */
    top: 39px !important;
    /* Default top position */


}


/* Base styles */
.header-icon {
    width: 40%;
    /* Default width for header icons */
    height: auto;
    filter: invert(100%);
}

.flag-icon {
    width: 15%;
    /* Default width for the flag icon */
    height: auto;
}

/* Responsive styles */
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
    .header-icon {
        width: 25px;
        /* Adjust width of header icons for smaller screens */
    }

    .flag-icon {
        width: 60%;
        /* Adjust width of flag icon for smaller screens */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .header-icon {
        width: 25px;
        /* Adjust width of header icons for medium screens */
    }

    .flag-icon {
        width: 40%;
        /* Adjust width of flag icon for medium screens */
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .flag-icon {
        width: 20%;
        /* Adjust width of flag icon for medium screens */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* No additional adjustments needed for extra large devices */
}

.logo-container {
    display: flex;
    justify-content: start;
}

.menu-items {
    text-decoration: none;
    color: white !important;
}

.menu-items2 {
    text-decoration: none;
    color: rgb(255, 255, 255) !important;
    background-color: #000 !important;
    border-radius: 10px;
    margin-right: 10px;
    width: auto;
    margin-bottom: 1rem;
}


.dropdown-menu {
    position: relative;
    padding: 10;
    z-index: 999;
    width: auto !important;
    overflow-y: auto;
    right: auto;
    /* margin-top: 5px; */
}

.teo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top-items,
.bottom-items {
    display: flex;
    flex-direction: row;
    border: 2px solid #000000 !important;
}



.dropdown-items {
    display: flex;
    /* Change flex direction to row */
    flex-wrap: wrap;
}

.dropdown-itemm {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-right: 1px solid #000000 !important;
    /* Add border to the right */
    margin-right: 10px;
    /* Add margin between items */
}

.dropdown-itemm:last-child {
    border-right: none;
    /* Remove border from the last item */
}

.dropdown-image {
    width: 200px !important;
    height: 100px !important;
    object-fit: cover !important;
    margin-right: 10px !important;
}

.black-text {
    color: black;
}


.menu-items2 .menuItems {
    width: auto !important;
    min-width: 100% !important;

}

@media (max-width: 767px) {
    .menu-items2 .menuItems {
        width: 100% !important;
        min-width: unset !important;
    }
}

.menu-item .dropdown-image {
    width: 100px;
    /* Adjust this value as needed */
    height: 100px;
    /* Adjust this value as needed */
    object-fit: cover;
    /* Maintain aspect ratio */
}


/* Hide the image on screens smaller than 768px */
@media (max-width: 767px) {
    .menu-item .dropdown-image {
        display: none;
    }
}

.menu-items2 .dropdown-menu {
    right: 0;
    left: 50%;
    transform: translateX(-70%);
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
    .menu-items2 .dropdown-menu {
        /* Adjustments for small screens */
        left: 0;
        transform: translateX(0);
        width: 100%;
        /* Optional: You might want to make the dropdown full width on small screens */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .menu-items2 .dropdown-menu {
        /* Adjustments for medium screens */
        transform: translateX(0%);
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .menu-items2 .dropdown-menu {
        /* Adjustments for large screens */
        transform: translateX(-50%);
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .menu-items2 .dropdown-menu {
        /* Adjustments for extra large screens */
        transform: translateX(-50%);
    }
}


.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    width: auto;
    /* Adjusts to content up to max-width */
    max-width: 700px;
    /* Maximum width */
    min-width: 500px;
    /* Minimum width */
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        /* Adjustments for small screens */
        width: 100%;
        /* Full width */
        max-width: none;
        /* Override max-width */
        min-width: 0;
        /* Override min-width */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        /* Adjustments for medium screens */
        width: auto;
        /* Adjusts to content up to max-width */
        max-width: 700px;
        /* Maximum width */
        min-width: 300px;
        /* Adjust minimum width as needed */
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        /* Adjustments for large screens */
        width: auto;
        /* Adjusts to content up to max-width */
        max-width: 700px;
        /* Maximum width */
        min-width: 400px;
        /* Adjust minimum width as needed */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        /* Adjustments for extra large screens */
        width: auto;
        /* Adjusts to content up to max-width */
        max-width: 700px;
        /* Maximum width */
        min-width: 500px;
        /* Minimum width */
    }
}



.menu-list {
    list-style-type: none;
    padding: 0;
}

.menu-item {
    margin-bottom: 20px;
    /* Adjust as needed */
}

.sub-menu {
    list-style-type: none;
    padding: 0;
    width: auto;
}

.sub-menu li {
    text-decoration: none;
    color: rgb(134, 126, 126);
    transition: color 0.3s;
    /* Smooth transition for color change */

}

.mainTitleMenu {
    text-decoration: none;
    font-weight: bold;
    color: #000;
    transition: color 0.3s;
    /* Smooth transition for color change */

}

.mainTitleMenu:hover {
    color: rgb(134, 126, 126);
}

.menu-item {
    cursor: pointer;
    /* Show pointer cursor on hover */
}

.sub-menu li:hover {
    /* Change color on hover */
    color: #000;
}

.search-icon {
    width: 24px !important;
    height: 24px !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 10px !important;
    z-index: 1 !important;
}

.search-input {
    padding: 10px 40px !important;
    width: 100% !important;
    max-width: 400px !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    position: relative !important;
    padding-left: 40px !important;
}

.search-input:focus {
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}