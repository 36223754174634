.sub-page {
    padding-top: 182px;
    text-align: left;

}

.image-frame {
    max-width: 100%; /* Ensure the container is responsive */
    height: 30rem; /* Allow the height to adjust according to the aspect ratio */
    overflow: hidden;
    margin: 1rem auto;
}

.subitem-img {
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    object-fit: cover;
    object-position: center;
    transition: transform 3s ease-in-out; /* Only transition transform property */
}

.subitem-img:hover {
    transform: scale(1.3);
}


.subItemPage-img {
    width: 100%;
    height: auto;
    max-height: 15rem;
}

.col h2 {
    font-weight: bolder;
}