/* .about-img {
    width: 100%;
    height: auto;
    max-height: 15rem;
} */

.about-page {
    padding-top: 182px;
}

.about-img {
    width: 100%;
    height: auto;
    max-height: 300px;
    /* Adjust the maximum height as needed */
    object-fit: cover;
    /* Ensures the image covers the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Adds a subtle shadow */
}

@media (max-width: 767px) {
    .menu {
      order: 2; /* Move menu to the bottom */
      margin-top: 20px; /* Add some space between content and menu */
    }
  
    .content {
      order: 1; /* Move content to the top */
    }
  }


.menu ul {
    list-style-type: none;
    padding: 0;
}

.menu ul li {
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    /* Display items as flex to align the image and text horizontally */
    align-items: center;
    /* Center items vertically */
}

.menu ul li .menu-icon {
    width: 30px;
    /* Adjust width as needed */
    margin-right: 10px;
    /* Adjust margin as needed to create space between image and text */
}

.menu ul li:hover {
    color: #007bff;
}

ul li {
    margin-bottom: 10px;
}

.content {
    text-align: left;
    width: 100%;
}

.content h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.content p {
    font-size: 1.1rem;
    line-height: 1.6;
}

.content h5 {
    font-size: 28px;
}

.main-title {
    color: #333;
    font-weight: bold;
}

.name-title {
    font-weight: bold;
}

.second-title {
    font-weight: bold;
    font-style: italic;
}

.third-title {
    font-style: italic;
}