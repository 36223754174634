.contact-container {
    padding-bottom: 50px;
    /* Add some space at the bottom */
}

.contact-img {
    width: 100%;
    height: auto;
    max-height: 300px;
    /* Adjust the maximum height as needed */
    object-fit: cover;
    /* Ensures the image covers the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Adds a subtle shadow */
}

.world-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
}

.container-form {
    width: 100%;
}


.contact-container {
    text-align: left;
    padding-top: 182px;
}

.contact-header {
    font-size: 25px;
    margin-left: 15px;
}

.contact-container h2 {
    color: #333;
    /* Change the heading color */
    margin-bottom: 20px;
    /* Add space below headings */
}

.contact-container p {
    margin-bottom: 10px;
    /* Add space below paragraphs */
}

.contact-container form {
    background-color: #f9f9f9;
    /* Change the background color of the form */
    padding: 20px;
    /* Add some padding to the form */
    border-radius: 10px;
    /* Add rounded corners to the form */
    width: 100%;
    justify-content: center;
}

.contact-container form label {
    font-weight: bold;
    /* Make form labels bold */
}

.contact-container form input[type='text'],
.contact-container form input[type='email'],
.contact-container form textarea {
    margin-bottom: 15px;
    /* Add space below form inputs */
}

.contact-container form button {
    margin-top: 10px;
    /* Add space above the submit button */
}

.contact-element {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.contact-locations {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 16px;
}

.contactIcon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 15px;
}

.iconBg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.contact-element h1 {
    font-size: 25px;
    margin: 0;
    padding: 0;
}

.contact-element p {
    margin: 0;
    padding: 0;
}

a[href^="tel:"] {
    color: black;
    text-decoration: none;
}

a[href^="mailto:"] {
    color: black;
    text-decoration: none;
}

.contact-social-media-icon {
    width: 70%;
    /* Set the width of social media icons to fill the container */
    height: auto;
    /* Ensure aspect ratio is maintained */
  }

/* Responsive styles */
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
    .contact-icon {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-right: 15px;
        margin-left: 12px;
    }

    .contact-element h1 {
        font-size: 15px;
        margin: 0;
        padding: 0;
    }

}