.main-page {
    padding-top: 182px
}

.card-image-frame {
    max-width: 100%;
    /* Ensure the container is responsive */
    height: 10rem;
    /* Allow the height to adjust according to the aspect ratio */
    overflow: hidden;
    margin: 1rem auto;
}

.exec-summary-images2 {
    height: 100%;
    width: 75%;
    margin-top: 1rem;
    object-fit: cover;
    object-position: center;
    transition: transform 3s ease-in-out;
}

.exec-summary-images2:hover {
    transform: scale(1.3);
}

/* .exec-summary-images:hover {
    transform: scale(1.3);
} */

.mainItemPage-img {
    width: 100%;
    height: auto;
    max-height: 15rem;
}

.container h1 {
    font-weight: bold;
}